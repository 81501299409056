import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="lightBg" style={{ padding: '50px 0' }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Real Time Information</h1>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
      <div className="whiteBg" id="websites">
        <div className="container" style={{ marginTop: '60px' }}>
          <HeaderInfo>
            <h1 className="font40 extraBold">Available Websites</h1>
            <p className="font13">
              Explore a wide range of supported websites for subtitle downloads with HeroSubs:
            </p>
          </HeaderInfo>
          <BlogItemList>
            <BlogItemContainer>
              <img src="/youtube.png" width="30" height="30" alt="YouTube" />
              <BlogBox
                title="YouTube"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </BlogItemContainer>
            <BlogItemContainer>
              <img src="/facebook.png" width="30" height="30" alt="Facebook" />
              <BlogBox
                title="Facebook"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </BlogItemContainer>
            <BlogItemContainer>
              <img src="/twitter.png" width="30" height="30" alt="Twitter" />
              <BlogBox
                title="Twitter"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </BlogItemContainer>
            <BlogItemContainer>
              <img src="/tiktok.png" width="30" height="30" alt="tiktok" />
              <BlogBox
                title="TikTok"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </BlogItemContainer>
            <BlogItemContainer>
              <img src="/google-drive.png" width="30" height="30" alt="google-drive" />
              <BlogBox
                title="Google Drive"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </BlogItemContainer>
            <BlogItemContainer>
              <img src="/dailymotion.png" width="30" height="30" alt="dailymotion" />
              <BlogBox
                title="Dailymotion"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </BlogItemContainer>
          </BlogItemList>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  margin-bottom: 50px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const BlogItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const BlogItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px; /* Ajusta el espaciado entre los elementos */
`;

// Si deseas agregar más estilos, puedes continuar aquí
