
const getYoutubeId = (link) => {
    // Regular expression to verify if the link is from YouTube
    const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?.*v=|embed\/|.*\?v=)?([^&/?]+)/;

    // Excecute the regular expression in this link
    const match = link.match(regex);

    // If there is no match, return null
    if (!match) {
        return null;
    }

    // Youtube id is in the [1] (second) position
    const id = match[1];

    return id;
}

export default getYoutubeId;