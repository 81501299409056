import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import TextInput from "../Inputs/TextInput";
import FullButton from "../Buttons/FullButton";
import PulseLoader from "react-spinners/PulseLoader";
//import subsHook from '../../hooks/subsHook';
import getYoutubeId from '../../tools/keyExtractor';



export default function SearchCaption() {

  const navigate = useNavigate();

  const [url, setUrl] = useState(null);

  const [animation, setAnimation] = useState(false);

  //const { postData, response, error, loading } = subsHook();
  //const { postData, loading } = subsHook();

  useEffect(() => {
    if (!animation) return;

    const timeoutId = setTimeout(() => {
      setAnimation(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [animation]);

  const onChange = (value) => {
    setUrl(value);
  }

  const handleLogout = () => {
    const videoId = getYoutubeId(url);
    if (videoId !== null) {
      
      setAnimation(true);
      setTimeout(() => {
        navigate(`/video/${videoId}`);

      }, 2000);
      //postData(videoId);
    }
  };

  return (
    <Wrapper id="services">
      <div className="redBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Free Video Caption Downloader</h1>
            <FormWrapper>
              <TextInputWrapper>
                <TextInput placeholder="Enter a URL to download subtitles" onChange={onChange} />
              </TextInputWrapper>
              {!animation ? (
                <BtnWrapper>
                  <FullButton title="Download" action={handleLogout} />
                </BtnWrapper>
              ) : (
                <PulseWrapper>
                  <PulseLoader color="#7620ff" />
                </PulseWrapper>
              )}
            </FormWrapper>
            <Paragraph className="font13">
              Download video subtitles quickly and for free from YouTube, Meta, Drive, dailymotion and more.
              <br />
              Enhance accessibility and viewing experience with our tool. Optimize your multimedia content search now!
            </Paragraph>
          </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderInfo = styled.div`
  text-align: center;
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const TextInputWrapper = styled.div`
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 30px;
  }
`;

const BtnWrapper = styled.div`
  width: 100px;
  backgroud-coor: red;
`;

const PulseWrapper = styled.div`
  width: 100px;
  display: flex;
  justify-content: center; /* Alinea los elementos horizontalmente en el centro */
  align-items: center; /* Alinea los elementos verticalmente en el centro */
`;

const Paragraph = styled.p`
  margin-top: 20px; /* Ajusta este valor según sea necesario */
`;

