import React from "react";
// Sections
import { useParams } from 'react-router-dom';

import TopNavbar from "../components/Nav/TopNavbar";
//import Header from "../components/Sections/Header";
import SearchCation from "../components/Sections/SearchCaption";
import Services from "../components/Sections/Services";
//import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
//import Pricing from "../components/Sections/Pricing";
//import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function Result() {

  const { videoId } = useParams();

  console.log('videoId:', videoId);

  return (
    <>
      <TopNavbar />
      <SearchCation />
      <Services />
      <Blog />
      <Footer />
    </>
  );
}


