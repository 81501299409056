import React from "react";
import styled from "styled-components";

export default function TextInput({ value, onChange, placeholder }) {
  return (
    <Input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
}

const Input = styled.input`
  width: 500px; /* Ancho grande */
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
  
  ::placeholder {
    color: #aaa;
  }

  :focus {
    border-color: #7620ff;
  }

  @media (max-width: 768px) {
    width: 100%; /* Ancho completo en pantallas pequeñas */
    max-width: 400px; /* Ancho máximo en pantallas medianas */
    margin: 0 auto; /* Centrar en pantallas medianas */
  }
`;
